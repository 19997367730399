.container {
	&-main-wrap {
		min-height: 100vh;
		padding: 20px;
		padding-top: 80px;
	}
	
	&-max {
		max-width: 1920px;
		width: 100%;
		margin: auto;
	}
}

.card-main {
	background-color: var(--color-white);
	padding: 15px;
	outline: 1px solid var(--color-grey-2);
	border-radius: 10px;
}

.card-list {
	background-color: var(--color-white);
	padding: 15px;
	outline: 1px solid var(--color-grey-3);
	border-radius: 10px;
}

.icon-circle-border {
	display: inline-block;
	width: 24px;
	height: 24px;
	color: var(--color-grey-6);
	font-size: 18px;
	text-align: center;
	line-height: 0;
	padding-top: 2px;
	border: solid 1px var(--color-grey-6);
	border-radius: 50%;
}


/* ============================================================
users circle
============================================================ */
.users-list {
	.user-circle {
		margin-left: -18px;
		border: solid 1px var(--color-white);
	}
}
.users-list {
	.user-circle-letters-sm {
		margin-left: -18px;
		border: solid 1px var(--color-white);
	}
}
/*  */
.user-circle {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 80px;
	color: var(--color-white);
	background-color: var(--color-grey-3);
	border-radius: 50%;
	overflow: hidden;
	
	img {
		width: 100%;
	}
	
	&.lg {
		width: 120px;
		height: 120px;
		font-size: 40px;
	}
	&.sm {
		width: 40px;
		height: 40px;
	}
	&.xs {
		width: 28px;
		height: 28px;
		font-size: 11px;
	}
}
/*  */
.user-circle {
	.MuiTypography-root {
		color: var(--color-white);
	}
}
/*  */
[class*="user-circle-"] { 
	overflow: hidden;
}


/* ============================================================
badges
============================================================ */
.badges {
    display: inline-block;
	color: var(--color-white);
	line-height: 1;
	/* background-color: var(--color-grey-5); */
	padding: 2px 6px;
	margin: 0 2px 4px;
	border-radius: 4px;
	
	&.back-dark {
		background-color: var(--color-grey-8);
	}
	&.back-grey {
		background-color: var(--color-grey-5);
	}
	&.border-color-dark {
		color: var(--color-grey-8);
		border: solid 1px var(--color-grey-8);
	}
}


/* ============================================================
title line middle
============================================================ */
.title-line-middle {
	position: relative;
	text-align: center;
	
	span {
		position: relative;
		background-color: var(--color-white);
		padding: 2px 4px;
	}
	
	&::before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		width: 100%;
		height: 1px;
	}
	
	&.grey {
		color: var(--color-grey-3);
	}
	&.grey::before {
		background: var(--color-grey-3);
	}
}


/* ============================================================
dots
============================================================ */
.dot-sm {
	display: inline-block;
	width: 10px;
	height: 10px;
	background-color: var(--color-grey-5);
	border-radius: 50%;
	
	&.blue-1 {
		background-color: var(--color-blue-1);
	}
	&.blue-4 {
		background-color: var(--color-blue-4);
	}
	&.danger {
		background-color: var(--color-danger);
	}
}


/* ============================================================
charts datos
============================================================ */
.charts-wrap {
	position: relative;
	width: 120px;
	height: 120px;
	margin: auto;
	
	&.doble {
		width: 140px;
		height: 140px;
	}
}

/* donuts */
.circle-datos {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 120px;
	height: 120px;
	color: var(--color-grey-7);
	margin: auto;
	border: solid 7px var(--color-grey-1);
	border-radius: 50%;
	
	&.lg {
		width: 140px;
		height: 140px;
	}
	&.md {
		width: 90px;
		height: 90px;
	}
}

/* pie */
.pie .line-back {
	position: absolute;
	width: 120px;
	height: 120px;
	border: solid 8px var(--color-grey-1);
	border-radius: 50%;
}

@property --p {
	syntax: '<number>';
	inherits: true;
	initial-value: 0;
}

.pie {
	--p:20;
	--b:8px;
	--c:darkred;
	--w:120px;
	
	aspect-ratio:1;
	position:relative;
	display:inline-grid;
	place-content:center;
	width:var(--w);
	text-align: center;
	line-height: 1;
	/* margin:5px; */
}
.pie:before,
.pie:after {
	content:"";
	position:absolute;
	z-index: 1;
	border-radius:50%;
}
.pie:before {
	inset:0;
	background:
		radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
		conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
		-webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
			mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
}
.pie:after {
	inset:calc(50% - var(--b)/2);
	background:var(--c);
	transform:rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
}
.animate {
	animation:p 1s .5s both;
}
.no-round:before {
	background-size:0 0,auto;
}
.no-round:after {
	content:none;
}
@keyframes p {
	from{--p: 0}
}

.sortable-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	padding: 20px 10px;
	list-style: none;
  }
  
  .sortable-item {
	width: 100%;
	display: flex;
	text-align: center;
	line-height: 25px;
	padding: 18px 20px;
	background-color: #fff;
	box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
	  0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
	border-radius: calc(4px / var(--scale-x, 1));
	box-sizing: border-box;
	list-style: none;
	color: #333;
  }
  
  .sortable-item span {
	padding-left: 10px;
  }

  
  .dragging-dbd-kit {
	background-color: #BFD8D5;
  }
  
  .dragging-handle-container {
	float: left;
	display: flex;
	width: 30px;
	min-height: 1px;
  }

  .dragging-title{
	float: left;
	// display: flex;
	width: 80px;
  }
  
  .dragging input{
	float: left;
	display: flex;
  }
  
  .dragging-handle {
	display: flex;
	width: 12px;
	padding: 5px 15px;
	align-items: center;
	justify-content: center;
	flex: 0 0 auto;
	touch-action: none;
	cursor: var(--cursor, pointer);
	border-radius: 5px;
	border: none;
	outline: none;
	appearance: none;
	background-color: transparent;
	-webkit-tap-highlight-color: transparent;
  }
  
  .dragging-handle:hover {
	background-color: rgba(0, 0, 0, 0.05);
  }
  
  .dragging-handle svg {
	flex: 0 0 auto;
	margin: auto;
	height: 100%;
	overflow: visible;
	fill: #919eab;
  }
  