@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?4il9lu');
  src:  url('fonts/icomoon.eot?4il9lu#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?4il9lu') format('truetype'),
    url('fonts/icomoon.woff?4il9lu') format('woff'),
    url('fonts/icomoon.svg?4il9lu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-profile_ic:before {
  content: "\e900";
}
.icon-cnv-emotion:before {
  content: "\e901";
}
.icon-add_ic:before {
  content: "\e902";
  /* color: #707070; */
}
.icon-arrow_back:before {
  content: "\e903";
  /* color: #707070; */
}
.icon-arrow_next:before {
  content: "\e904";
  /* color: #707070; */
}
.icon-dropdown:before {
  content: "\e905";
}
.icon-slide-btnn_left:before {
  content: "\e906";
}
.icon-slide-btnn_right:before {
  content: "\e907";
}
.icon-arrow-ic_disminuir:before {
  content: "\e908";
}
.icon-arrow-ic_agregar:before {
  content: "\e909";
}
.icon-borrar_icon:before {
  content: "\e90a";
  color: #bcbcbc;
}
.icon-cam-ic:before {
  content: "\e90b";
  /* color: #fff; */
}
.icon-cerrar_icon:before {
  content: "\e90c";
  /* color: #707070; */
}
.icon-cnv-clip:before {
  content: "\e90d";
}
.icon-cnv-files:before {
  content: "\e90e";
}
.icon-cnv-link:before {
  content: "\e90f";
}
.icon-cnv-mark:before {
  content: "\e910";
}
.icon-cnv-mencion:before {
  content: "\e911";
}
.icon-cnv-message-mini:before {
  content: "\e912";
}
.icon-configuration_ic:before {
  content: "\e913";
}
.icon-filtro-icon_v2:before {
  content: "\e914";
  /* color: #707070; */
}
.icon-copiar_icon:before {
  content: "\e915";
}
.icon-download-ic:before {
  content: "\e916";
}
.icon-eye_icon:before {
  content: "\e917";
}
.icon-eye-cross_icon:before {
  content: "\e918";
}
.icon-finalizado-icon:before {
  content: "\e919";
  /* color: #707070; */
}
.icon-history-ic:before {
  content: "\e91a";
  /* color: #707070; */
}
.icon-ic_editar:before {
  content: "\e91b";
  /* color: #707070; */
}
.icon-ic_exito:before {
  content: "\e91c";
  /* color: #fff; */
}
.icon-ic_move-active:before {
  content: "\e91d";
}
.icon-ichead_alert:before {
  content: "\e91e";
  /* color: #707070; */
}
.icon-ichead_message:before {
  content: "\e91f";
  /* color: #707070; */
}
.icon-ichead_profile:before {
  content: "\e920";
  /* color: #707070; */
}
.icon-img_copiar:before {
  content: "\e921";
}
.icon-img_exito:before {
  content: "\e922";
  /* color: #fff; */
}
.icon-logout_ic:before {
  content: "\e923";
}
.icon-mark_active:before {
  content: "\e924";
  color: #f7691e;
}
.icon-mark_inactive:before {
  content: "\e925";
  /* color: #cccbcb; */
}
.icon-meet-default:before {
  content: "\e926";
}
.icon-meet-fill:before {
  content: "\e927";
  /* color: #fff; */
}
.icon-meet-new:before {
  content: "\e928";
}
.icon-rc_btnn-add:before {
  content: "\e929";
  /* color: #cccbcb; */
}
.icon-rc_btnn-quitar:before {
  content: "\e92a";
  /* color: #707070; */
}
.icon-rc_btnn-cancel_v2:before {
  content: "\e92b";
}
.icon-rc_btnn-check_v2:before {
  content: "\e92c";
}
.icon-rc_ic-cancel:before {
  content: "\e92d";
  /* color: #fff; */
}
.icon-rc_ic-check:before {
  content: "\e92e";
  /* color: #fff; */
}
.icon-ic_check:before {
  content: "\e92f";
  color: #2acca3;
}
.icon-ic_cross:before {
  content: "\e930";
  color: #f46161;
}
