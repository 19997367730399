:root {
	font-size: 16px;
	
	/* colors */
	--color-blue-1:		#70CAFA;
	--color-blue-2:		#44A3DE;
	--color-blue-3:		#348AC0;
	--color-blue-4:		#026CAD;
	--color-pink-1:		#E47BFD;
	--color-pink-2:		#D904D9;
	--color-green:		#2DC427;
	--color-orange:		#F7691E;
	
	--color-grey-1:		#F3F3F3;
	--color-grey-2:		#e8e8e8;
	--color-grey-3:		#CCCBCB;
	--color-grey-4:		#C3BFBF;
	--color-grey-5:		#BCBCBC;
	--color-grey-6:		#6A6969;
	--color-grey-7:		#707070;
	--color-grey-8:		#3E3E3E;
	--color-grey-9:		#211f1f;
	
	--color-font:		#707070;
	--color-dark-1:		#3E3E3E;
	--color-dark-2:		#211f1f;
	--color-dark:		#211f1f;

	--color-white:		#ffffff;
	--color-black:		#000000;
	
	--color-success:	#2DC427;
	--color-danger:		#f46161;
	--color-warning:	#F8E713;
	
	--back-body:		#f4f4f4;
}

html,
body {
    height: 100%;
    box-sizing: border-box;
}

body {
	font-size: .875rem;
	background-color: var(--back-body);
	margin: 0;
}