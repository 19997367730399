/* ============================================================
buttons
============================================================ */
@mixin attrBtn($color, $colorBg) {
	color: $color;
	background: $colorBg
}

.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	height: 40px;
	line-height: 1;
	padding: 2px 20px;
	border: none;
	border-radius: 8px;
	
	&:disabled {
		background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3)) !important;
		color: -internal-light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
		border-color: -internal-light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3)) !important;
		opacity: .25;
	}
	
	&.large {
		height: 60px;
	}

	/* */
	&.white {
		@include attrBtn(var(--color-font), var(--color-white))
	}
	&.dark-1 {
		@include attrBtn(var(--color-white), var(--color-dark-1))
	}
	&.dark {
		@include attrBtn(var(--color-white), var(--color-dark))
	}
	&.grey-light {
		@include attrBtn(var(--color-font), var(--color-grey-1))
	}
	&.blue-2 {
		@include attrBtn(var(--color-white), var(--color-blue-2))
	}
	
	/*  */
	&.border-grey-light {
		@include attrBtn(var(--color-grey-7), none);
		outline: solid 1px var(--color-grey-2);
	}
	&.border-grey-dot {
		@include attrBtn(var(--color-grey-7), none);
		outline: dashed 1px var(--color-grey-3);
	}
}

/* button projectitem */
.button-projectitem {
	display: block;
	width: 100%;
	padding: 0;
	border: none;
}


/* ============================================================
inputs
============================================================ */
.form-control {
	border: solid 1px var(--color-grey-3);
}

.input-group {
	&.over-icon .form-control {
		padding-right: 40px;
		border-radius: .375rem !important;
	}
	&.over-icon .btn {
		position: absolute;
		right: 0;
		border: none;
	}
}

.input-botton-brd {
	border-top-style: hidden;
	border-right-style: hidden;
	border-left-style: hidden;
	border-bottom-style: groove;
}

.no-outline:focus {
	outline: none;
}

.msgError {
	color: #bf1650;
}
.msgError::before {
	display: inline;
}

.react-select-container {
	width: 60px;

	[class*=-control] {
		border: none !important;
		background: none;
	}
	[class*=-control]:hover {
		border: 0px;
	}
	[class*=-ValueContainer] {
		padding: 2px 0;
	}
	[class*=-indicatorSeparator] {
		display: none;
	}
	[class*=-indicatorContainer] {
		padding: 8px 0;
	}
}

/* ============================================================
dropdowns
============================================================ */
.dropdown-main {
	.dropdown-toggle {
		position: relative;
		display: inline-block;
		line-height: 1;
		white-space: inherit;
		padding-right: 15px;
	}
	.dropdown-toggle::after {
		content: "\e905";
		position: absolute;
		right: 0;
		/* top: 50%; */
		color: var(--color-dark);
		font-family: 'icomoon' !important;
		font-size: 14px;
		line-height: 1.2;
		border: none;
		/* transform: translateY(-50%); */
	}
	.dropdown-toggle.show::after {
		transform: rotate(180deg);
	}

	/* sin arrow */
	&.arrow-none .dropdown-toggle {
		padding: 0;
	}
	&.arrow-none .dropdown-toggle::after {
		content: none;
	}

	/* mui material */
	& .MuiListItemText-secondary {
		white-space: nowrap;
	}
	.MuiList-root {
		position: relative;
		background: none;
	}
	.MuiListItem-root {
		display: flex;
		align-items: center;
		padding: 2px;
		padding-right: 20px;

		&::after {
			content: "\e905";
			position: absolute;
			right: 0;
			top: 32%;
			color: var(--color-font);
			font-family: 'icomoon' !important;
			font-size: 14px;
			line-height: 1;
			border: none;
		}
		&[aria-expanded="true"]::after {
			transform: rotate(180deg);
		}

		.MuiListItemIcon-root {
			margin-top: 0;
		}
	}
	.MuiListItem-root:hover {
		background: none;
	}
	// .MuiListItem-root[aria-expanded="true"] .arrow-dropdown {
	// 	background-color: red;
	// }
}

.MuiListItem-root {
	padding-top: 4px;
	padding-bottom: 4px;
}

.dropdown-resources {
	& .dropdown-toggle {
		position: relative;
		font-size: 1rem;
		background-color: var(--color-white);
		padding: 10px;
		border: 1px solid var(--color-grey-3);
	}
	& .dropdown-toggle::after {
		position: absolute;
		right: 12px;
		content: "\F282";
		font-family: bootstrap-icons !important;
		font-size: 14px;
		font-weight: bold;
		border: none !important;
	}
}

/* confirmacion */
.confirmacion {
	position: fixed;
	top: -120px;
	left: 0;
	right: 0;
	z-index: 5;
	display: flex;
	align-items: center;
	gap: 10px;
	width: 100%;
	height: 120px;
	line-height: 1;
	background-color: var(--color-blue-2);
	padding: 20px 30px;
	transition: all 0.25s;
	
	&.show {
		top: 0;
	}
}