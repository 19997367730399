/* ============================================================
login
============================================================ */
.login-wrap {
	max-width: 400px;
	width: auto;
}

/* .login-change-pass {
	padding-left: 80px;
	border-left: solid 1px var(--color-grey-3);
} */

.image-login-pass {
	display: inline-block;
	max-width: 220px;
}
.image-login-pass img {
	width: 100%;
}


/* ============================================================
sections - new project
============================================================ */
.dashboard-listprojects {
	max-height: 660px;
}
.dashboardwrap-listprojects {
	max-height: 560px;
    padding: 2px;
    overflow: auto;
}


/* ============================================================
sections - new project
============================================================ */
/* .new-project-top {
	min-height: 140px;
} */

.grid-new-project {
	background-color: #16A085;
	/* height: 100%; */
    height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
	display: grid; 
	grid-template-columns: 1fr 1fr 1fr 1fr; 
	grid-template-rows: 1fr 1fr 1fr 1fr; 
	gap: 15px 15px; 
	grid-auto-rows: min-content;
	grid-template-areas: 
		"a-names-charts a-names-charts a-names-charts a-reports-links"
		"a-project-plans a-project-plans a-project-plans a-resources"
		"a-project-plans a-project-plans a-project-plans a-resources"
		"a-project-plans a-project-plans a-project-plans a-resources"; 
}
.a-names-charts { grid-area: a-names-charts; border: solid 1px #000; }
.a-reports-links { grid-area: a-reports-links; border: solid 1px #000; }
.a-project-plans { grid-area: a-project-plans; border: solid 1px #000; }
.a-resources { grid-area: a-resources; border: solid 1px #000; }

.overflowwrap {
	height: calc(100% - 65px);
	/* border: solid 1px blue; */
	overflow: auto;
}
.overflowarea {
	/* height: calc(100% - 50px); */
	/* height: 500px; */
	/* max-height: calc(100% - 50px); */
	/* border: solid 1px red; */
	padding: 1px;
	/* overflow-y: auto; */
}
.overflowresources {
	/* height: calc(100% - 290px); */
	/* border: solid 1px violet; */
	overflow-y: auto;
	margin-bottom: 20px;
	padding: 2px 5px 0 0;

	&.sm {
		height: calc(100% - 290px);
	}
	&.md {
		height: calc(100% - 180px);
	}
	&.lg {
		height: calc(100% - 130px);
	}
}

.overflow-card-resources {
	height: calc(100% - 56px);
}

.dashboard-overflow-list-resources {
	max-height: 480px;
	overflow: auto;
}

/* nombre de proyectos */
.names-projects {
	.input-name-pro {
		margin-bottom: -2px;
	}
}

/* chart y list */
.chart-list {
	max-width: 350px;
	width: 100%;
}

/* .container-plans {
	min-height: calc(100vh - 290px);
	background-color: rgba(0,0,0, 0.1);
} */
.container-plans .height-plans {
	height: calc(100vh - 290px);
	/* max-height: calc(100vh - 290px); */
	/* background-color: rgba(244, 2, 2, 0.1); */
}

/* add plans items */
.colum-sm {
	width: 110px;
}
/*  */
.add-plans-items {
	.add-item {
		display: flex;
		align-items: center;
		color: var(--color-grey-7);
		margin-bottom: 20px;
	}
	& .add-item .icon {
		width: 30px;
	}
	& .add-item .button {
		height: 40px;
	}
}
/* names plans items */
.plans-items {
	color: var(--color-grey-7);
	margin-bottom: 20px;
	
	.icon {
		width: 30px;
	}
	
	.plan-item {
		outline: solid 1px var(--color-grey-3);
		border-radius: 8px;

		.names {
			display: flex;
			align-items: center;
			gap: 10px;
			line-height: 1;
			height: 40px;
			padding: 20px 10px;
		}
		.names .form-control {
			border: none;
		}
		.sub-names {
			display: flex;
			gap: 10px;
			padding: 2px 10px 8px;
		}
		.plan-tit {
			width: 60px;
		}
		&.selected {
			background-color: var(--color-grey-8);
			color: var(--color-white);
		}
		&.selected-relation {
			background-color: var(--color-grey-3);
			color: var(--color-white);
		}
		&.selected .names,
		&.selected .plan-tit,
		&.selected .plan-name {
			color: var(--color-white);
		}

		/*  */
		.tit-collapse {
			position: relative;
			padding-left: 15px;
		}
		.tit-collapse::before {
			content: "\e905";
			position: absolute;
			left: 0;
			color: var(--color-dark);
			font-family: 'icomoon' !important;
			transform: rotate(180deg);
		}
		.tit-collapse.collapsed::before {
			transform: rotate(0);
		}
	}

	/* */
	.plan-badges-list {
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}
	.plan-badges-list .badges {
		margin: 0;
		cursor: pointer;
	}
}

.activity-add-row {
	display: flex;
	/* gap: 20px; */
	margin: 10px 0;

	> div {
		padding: 8px;
	}
	span {
		display: inline-block;
		text-align: center;
	}
	
	.w-sm {
		width: 20px;
	}
	.w-punt {
		width: 43px;
	}
	.w-rec {
		width: 48px;
	}
	.w-est {
		width: 101px;
	}
	.w-prio {
		width: 56px;
	}
	.w-sel {
		width: 92px;
	}
}

.input-table-activities {
	background: none;
	border: none;

	&.num {
		width: 65px;
		text-align: center;
	}
	&:focus-visible {
		outline: none;
	}
}

.table-activities-empty {
	max-width: 450px;
	color: var(--color-dark-1);
	text-align: center;
	margin: 40px auto;
}


/* ============================================================
sections - conversation
============================================================ */
.conver-list-menu {
	height: 100%;
	padding: 20px 0 20px 0;
	border-right: solid 1px var(--color-grey-3);
}

.conver-content {
	max-width: 900px;
	margin: auto;
}


/* ============================================================
sections - history
============================================================ */
.history-wrap-list {
	max-width: 1000px;
	margin: auto;
}

.history-list {
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	li {
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 10px;
		margin-bottom: 2px;
		border: solid 1px var(--color-grey-3);
		border-radius: 4px;
	}
}


/* ============================================================
deliveries
============================================================ */
.deliveries-items {
	display: flex;
	align-items: center;
	gap: 10px;
	min-height: 40px;
	color: var(--color-font);
	padding: 15px 10px;
	
	.names {
		line-height: 1.4;
	}

	&.selected {
		color: var(--color-black);
		background-color: var(--color-grey-1);
	}
}

.deliveries-comments {
	min-height: 200px;
	background-color: var(--color-grey-1);
	padding: 10px;
}