/* ============================================================
mediaqueries
============================================================ */
@media (max-width: 1200px) {
	.chart-list {
		max-width: auto;
		width: auto;
	}
}

@media (max-width: 1024px) {
	.container-plans .height-plans {
		height: calc(100vh - 360px);
	}

	/* names projects, chart y list */
	.namesprojects-charts .names-projects {
		width: 100%;
	}
	.chart-list {
		max-width: 48%;
		width: 48%;
	}
}

@media (max-width: 768px) {
	.container-plans .height-plans {
		height: auto;
	}

	.dashboard-listprojects {
		max-height: 100%;
	}
	.dashboardwrap-listprojects {
		max-height: 480px;
	}

	.overflowarea {
		height: 320px;
		overflow: auto;
		margin-bottom: 20px;
	}
}

@media (max-width: 576px) {
	.container-main-wrap {
		padding-left: 10px;
		padding-right: 10px;
	}

	.navbar .container-fluid {
		padding-left: 15px;
		padding-right: 15px;
	}

	.chart-list {
		max-width: 100%;
		width: 100%;
	}

	.button.large {
		height: 40px;
		font-size: 0.875rem;
	}

	.table-simple {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
}