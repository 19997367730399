/* ============================================================
navbar
============================================================ */
.navbar {
    position: fixed !important;
	top: 0;
	z-index: 1;
	width: 100%;
	color: var(--color-font);
	border-bottom: solid 1px var(--color-grey-3);
    
    .container-fluid {
        padding-left: 35px;
        padding-right: 35px;
    }
}

.navbar-brand {
	display: block;
	width: 90px;
	padding: 0;
    
    img {
        display: block;
        width: 100%;
    }
}

.navbar-text {
    a {
        color: var(--color-font);
    }

    &.icons .icon:hover {
        color: var(--color-dark);
    }
}
// .navbar-text.icons .icon {
    // 	color: var(--color-font);
// }


/* ============================================================
list main
============================================================ */
.list-main {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    li {
        /* background-color: var(--color-back-2); */
        padding: 10px;
        /* margin-bottom: 10px; */
        
        &:last-child {
            margin: 0;
        }
        &:nth-child(odd) {
            background-color: var(--color-grey-1);
        }
    }

    /* */
    .wrap {
        display: flex;
        /* align-items: center; */
        flex-wrap: nowrap;
        line-height: 1;
        gap: 10px;
        
        > * {
            margin: 0;
        }
        &.all-flex-1 > * {
            flex: 1;
        }
    }

    /* */
    .buttons {
        /* min-width: 70px; */
        text-align: center;
        
        span {
            margin: 0 4px;
        }
    }
    
    /* borders bottom */
    &.borders-bottom {
        li {
            background: none;
            padding: 0;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: solid 1px var(--color-grey-3);
            
            &:last-child {
                margin: 0;
            }
        }
    }

    /* back grey */
    &.back-grey li {
        background: var(--color-grey-1);
        margin-bottom: 10px;
        
        &:last-child {
            margin: 0;
        }
    }

    /* back none */
    &.back-none li {
        background: none;
        padding: 0;
        margin-bottom: 15px;
        
        &:last-child {
            margin: 0;
        }
    }

    /* lista de estimacion */
    &.list-estimacion .wrap > div:nth-child(2) {
        width: 30%;
    }
    &.list-estimacion .wrap > div:first-child {
        width: 25%;
    }
    &.list-estimacion .MuiLinearProgress-root {
        width: 100%;
    }
    &.list-estimacion .num {
        width: 65px;
    }
}


/* ============================================================
tabs
============================================================ */
.tabs-main {
    .nav-link {
        color: var(--color-font);
        padding-top: 0;
        margin: 0;
        border: none;
        border-bottom: solid 3px transparent;

        &.active {
            border-color: #000;
        }
    }
}


/* ============================================================
accordions
============================================================ */
.MuiAccordion-root {
    .MuiAccordionSummary-root .MuiTypography-root {
        font-size: .875rem;
        font-weight: bold;
    }
}


/* ============================================================
tables
============================================================ */
.table-simple {
    overflow: inherit;
    
    table thead th {
        font-weight: normal;
    }
    table tbody td {
        color: var(--color-font);
        line-height: 1;
        vertical-align: middle;
    }

    table thead th:not(:last-child),
    table tbody td:not(:last-child) {
        text-align: center;
    }
} 
/* mui material */
.MuiTable-root {
    th.MuiTableCell-root {
        color: var(--color-grey-4);
    }

    tr.MuiTableRow-root {
        background-color: var(--color-white);
    }
    
    td.MuiTableCell-body {
        background-color: var(--color-white);
        padding: .4rem .4rem;
    }
    
    .MuiList-root {
        padding: 0;
    }
}
.table-simple {
    .MuiList-root {
        padding: 0;
    }
}


/* ============================================================
alerts
============================================================ */
.alert-planing {
	position: absolute;
	left: 30px;
	right: 30px;
	bottom: 50px;
}