$ncols: 12;

$newbreakpoints: (
	'xxxl': 1600px,
	'xxxxl': 2000px
);

@mixin cols($bks, $cols, $prefix) {
	@each $name, $bk in $bks {
		@media(min-width: $bk) {
			@for $i from 1 to $ncols + 1 {
				.#{$prefix}-#{$name}-#{$i} {
					width: (($i * 100) / $ncols) * 1%;
				}
			}
		}
	}
}

@include cols($newbreakpoints, $ncols, 'col');

/* ============================================================
utilities
============================================================ */
a {
	text-decoration: none !important;
}

.rows-main {
	display: flex;
	gap: 10px;
}

.flex {
	&-end {
		display: flex;
		justify-content: flex-end;
	}
	&-100 {
		flex-basis: 100% !important;
	}
	&-none {
		flex: none;
	}
}

.gap {
	&-10 {
		gap: 10px;
	}
	&-20 {
		gap: 20px;
	}
	&-30 {
		gap: 30px;
	}
}

hr {
	height: 1px;
	color: inherit;
	background-color: var(--color-grey-3);
	margin: 1rem 0;
	border: 0;
	opacity: 1;
	
	&.vert {
		width: 1px;
		height: 100%;
		background-color: var(--color-grey-3);
		margin: 0 auto;
		border: 0;
	}
}

.min-width-auto {
	min-width: auto !important;
}

.max-height {
	max-height: 450px;
	overflow: auto;
}
/*  */
.min-height {
	&-40 {
		min-height: 40px;
	}
	&-60 {
		min-height: 60px;
	}
}
/*  */
.height-100 {
	height: 100%;
}

.pl-ext {
	padding-left: 80px;
}

$fonts-sizes: (
	11: 11px, 
	12: 12px, 
	14: .875rem, 
	16: 1rem, 
	18: 1.125rem, 
	20: 1.25rem, 
	24: 1.5rem,
	28: 1.75rem,
	32: 2rem,
);

@each $name, $size in $fonts-sizes {
	.font-#{$name} {
		font-size: $size;
	}
};

$colors-palette: (
	"blue-1": var(--color-blue-1),
	"blue-2": var(--color-blue-2),
	"blue-3": var(--color-blue-3),
	"blue-4": var(--color-blue-4),
	"pink-1": var(--color-pink-1),
	"pink-2": var(--color-pink-2),
	"green": var(--color-green),
	"grey-1": var(--color-grey-1),
	"grey-2": var(--color-grey-2),
	"grey-3": var(--color-grey-3),
	"grey-4": var(--color-grey-4),
	"grey-5": var(--color-grey-5),
	"grey-6": var(--color-grey-6),
	"grey-7": var(--color-grey-7),
	"grey-8": var(--color-grey-8),
	"grey-9": var(--color-grey-9),
	"font": var(--color-font),
	"font-dark": var(--color-dark-2),
	"dark-1": var(--color-dark-1),
	"dark": var(--color-dark),
	"white": var(--color-white),
	"black": var(--color-black),
	"success": var(--color-success),
	"danger": var(--color-danger),
	"warning": var(--color-warning),
);
/*  */

/* colors fonts */
@each $name, $color in $colors-palette {
	.color-#{$name} {
		color: $color;
	}
}

/* colors backs */
@each $name, $color in $colors-palette {
	.bg-#{$name} {
		background: $color !important;
	}
}
@each $name, $color in $colors-palette {
	.back-#{$name} {
		background: $color;
	}
}

.bg-gradient {
	&-blue {
		background: var(--color-blue-4);
		background: linear-gradient(0deg, var(--color-blue-4) 0%, var(--color-blue-1) 100%);
	}
	&-pink {
		background: var(--color-pink-2);
		background: linear-gradient(0deg, var(--color-pink-2) 0%, var(--color-pink-1) 100%);
	}
	&-dark {
		background: var(--color-grey-6);
		background: linear-gradient(0deg, var(--color-grey-6) 0%, var(--color-dark) 100%);
	}
}

/*  */

/* Desarrollo */
.bg-gradient {
	&-1 {
		background: #47CCFF;
		background: linear-gradient(0deg, #106080 0%, #47CCFF 100%);
	}
	/* Operaciones */
	&-2 {
		background: #0156C5;
		background: linear-gradient(0deg, #01085C 0%, #0156C5 100%);
	}
	/* Diseño */
	&-3 {
		background: #FFF024;
		background: linear-gradient(0deg, #E6A701 0%, #FFF024 100%);
	}
	/* Cuentas */
	&-4 {
		background: #65DE71;
		background: linear-gradient(0deg, #067809 0%, #65DE71 100%);
	}
	/* Calidad */
	&-5 {
		background: #DE67DC;
		background: linear-gradient(0deg, #991E96 0%, #DE67DC 100%);
	}
	/* Productos */
	&-6 {
		background: #FFA000;
		background: linear-gradient(0deg, #E66000 0%, #FFA000 100%);
	}
	/* ***** */
	&-7 {
		background: #F76B1C;
		background: linear-gradient(0deg, #F76B1C 0%, #E4B53A 100%);
	}
	&-8 {
		background: #20D153;
		background: linear-gradient(0deg, #20D153 0%, #08EAC8 100%);
	}
	&-9 {
		background: #16A085;
		background: linear-gradient(0deg, #16A085 0%, #F4D03F 100%);
	}
	&-10 {
		background: #784BA0;
		background: linear-gradient(0deg, #784BA0 0%, #FF3CAC 100%);
	}
}

.border {
	&-grey-3 {
		outline: 1px solid var(--color-grey-3);
	}
	&-dark-1 {
		outline: 1px solid var(--color-dark-1);
	}
	&-dark {
		outline: 1px solid var(--color-dark);
	}
	&-danger {
		outline: 1px solid var(--color-danger) !important;
	}
	&-bottom-danger {
		border-bottom: 1px solid var(--color-danger) !important;
	}
}
/*  */
.border-bottom {
	&-grey-3 {
		border-bottom: 1px solid var(--color-grey-3);
	}
	&-dark-1 {
		border-bottom: 1px solid var(--color-dark-1);
	}
	&-dark {
		border-bottom: 1px solid var(--color-dark);
	}
}

.rotate-90 {
	transform: rotate(90deg);
}